import { useLocation } from "react-router-dom";
import { countries as charts, genres } from "../constants/constants";
import Dropdown from "./dropdown";
import { TbDotsVertical } from "react-icons/tb";
import GenreItem from "./genre-item";
import CountryItem from "./country-item";
import { useNavStore } from "state/store";

const Navbar = ({ userData, navigate, setSidebarOpen }) => {
  const location = useLocation();
  const availableCountries = useNavStore((state) => state.availableCountries);
  const handleCountryDropdownClick = (country) => {
    navigate(`/#${country.id}`);
  };

  const handleGenreNavigate = (genre) => {
    navigate(`/genre/${genre.label}`);
  };

  return (
    <div className="max-md:hidden">
      <div className="flex justify-between w-full px-5 py-1 space-x-4 rounded bg-neutral-800 items-center">
        <div className="flex justify-center w-full px-5 py-1 rounded bg-neutral-800 items-center xl:gap-8">
          {Object.keys(charts).map((continent) => {
            return (
              <Dropdown
                key={continent}
                items={charts[continent]}
                ListComponent={CountryItem}
                label={continent}
                onClickCB={handleCountryDropdownClick}
                navigate={navigate}
                filterData={availableCountries}
              />
            );
          })}
          <Dropdown
            items={genres}
            ListComponent={GenreItem}
            label={"genres"}
            onClickCB={handleGenreNavigate}
          />
        </div>
        <div
          id="desktop-kebab"
          className="group cursor-pointer relative hover:bg-neutral-600 rounded-t-md px-1 pt-2 pb-4"
        >
          <TbDotsVertical className="h-8 w-8 text-white text-3xl" />
          <div
            id="kebab-list"
            className={`
              absolute flex-col z-20 hidden group-hover:flex
              transition-all duration-300
              flex-start gap-2 right-0 top-full
              w-auto min-w-60
              bg-neutral-700 py-4 px-4 rounded-md
              border-2 border-neutral-500 rounded-tr-none`}
          >
            {/* temporarily hidden for user testing */}
            {/* {userData && (
              <div
                className={`${
                  userData && userData.subActive ? "hidden" : "visible"
                } py-2 px-4 font-semibold text-black bg-white rounded-full cursor-pointer max-w-32`}
                onClick={() => {
                  navigate("/subscription");
                }}
              >
                Subscribe
              </div>
            )} */}
            {userData && (
                <div
                    className={`${
                        userData && !userData.subActive ? "hidden" : "visible"
                    } py-2 px-4 font-semibold text-black bg-white rounded-full cursor-pointer max-w-32`}
                    onClick={() => {
                      navigate("/subscription");
                    }}
                >
                  Unsubscribe
                </div>
            )}
            {userData && (
              <div
                onClick={() => {
                  navigate("/admin");
                  setSidebarOpen(false);
                }}
                className={`${userData?.isAdmin ?? "hidden"} ${
                  location.pathname === "/admin"
                    ? "bg-purple-500"
                    : "hover:bg-neutral-600"
                } font-medium text-white text-md p-2  rounded cursor-pointer select-none  `}
              >
                Admin Console
              </div>
            )}
            {userData ? (
              <div
                onClick={() => navigate("/logout")}
                className={`font-medium text-white p-2 cursor-pointer select-none text-md flex gap-1 items-center w-full border-t hover:bg-neutral-600`}
              >
                {/* <TbLogout className="text-2xl mb-1" /> */}
                <span> Logout </span>
              </div>
            ) : (
              <div
                className="font-medium text-white p-2 cursor-pointer select-none text-md flex gap-1 items-center w-full border-t hover:bg-neutral-600"
                onClick={() => navigate("/login")}
              >
                Sign In
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
