import { useEffect, useState } from "react";
import logo from "../assets/logos/kazang-wordmark-white.png";
import MobileNavItem from "./mobile-nav-item";
import { countries as charts, genres } from "../constants/constants";
import {  } from "react-icons/tb";
import africa from "../assets/icons/africa.png";
import eu from "../assets/icons/europe.png";
import northAmerica from "../assets/icons/north-america.png";
import asiaPacific from "../assets/icons/asia-pacific.png";
import southAmerica from "../assets/icons/south-america.png";
import genre from "../assets/icons/genre.png";
import Flag from "react-flagkit";
import { Link, useLocation } from "react-router-dom";
import GenreItem from "./genre-item";
import { useNavStore, useSongStore } from "state/store";
import { usePlayer } from "App";

import {
  TbUserCircle,
  TbSearch,
  TbMusicPlus,
  TbHome2,
  TbUsers,
  TbPlaylist,
  TbLibrary,
  TbInfoCircle,
  TbLogout,
  TbQuestionMark,
  TbDotsVertical,
  TbMenu2,
  TbX
} from "react-icons/tb";
import binoculars from "../assets/icons/binoculars.png";
import ImageViewer from "./image-viewer";
import { getAnyYouTubeVideoId } from "utils/get-video-id";

const srcMapping = {
  africa: africa,
  "north-america": northAmerica,
  "asia-pacific": asiaPacific,
  "south-america": southAmerica,
  europe: eu,
  genres: genre,
};

const mobileNavBarDropdowns = {
  ...charts,
  genres,
};

const MobileMenu = ({ userData, navigate }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isKebabExpanded, setKebabIsExpanded] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeDropdownList, setActiveDropdownList] = useState([]);
  const availableCountries = useNavStore((state) => state.availableCountries);

  const songStore = useSongStore();
  const player = usePlayer();
  const location = useLocation();
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    setActiveDropdownList([]);
  };
  const toggleKebab = () => {
    setKebabIsExpanded(!isKebabExpanded);
    setActiveDropdownList([]);
  };
  const handleContinentActivate = (continent) => {
    if (continent === activeDropdown) {
      // user tapped an already active continent. probably
      // trying to minimize
      closeContientDropdown();
      return;
    }
    setIsExpanded(false);
    setActiveDropdown(continent);
    if (continent !== "genres") {
      setActiveDropdownList(
        mobileNavBarDropdowns[continent].filter((country) =>
          availableCountries?.includes(country.id),
        ),
      );
      return;
    }
    setActiveDropdownList(mobileNavBarDropdowns[continent]);
  };

  const closeContientDropdown = () => {
    setActiveDropdownList([]);
    setActiveDropdown(null);
  };
  
  const randomSong = (e) => {
    // pick a  random song from our store and play it...
    setIsExpanded(false);
    const size = songStore.allSongs.length;
    const song = songStore.allSongs[Math.floor(Math.random() * size)];
    player.setSong({ ...song, id: getAnyYouTubeVideoId(song?.url) });
    player.ChangeMode("Random", "");
  };

  const ProfilePicture = (picture) => {
    return (
      <ImageViewer
        style={{ height: "28px", width: "28px" }}
        className="w-24 h-24 max-w-24 rounded-full shrink-0"
        image={picture}
      />
    );
  };

  const Explore = () => {
    return (
      <img
        src={binoculars}
        className="object-fill max-h-6"
        alt="binoculars icon, explore page"
      />
    );
  };

  useEffect(() => {
    setIsExpanded(false);
  }, [location.pathname]);

  return (
    <div id="mobile-menu" className="md:hidden fixed w-full z-40">
      <div className="flex items-center justify-between py-4 px-1 bg-neutral-800 relative">
        <TbMenu2 onClick={toggleExpanded} className="text-white text-4xl" />
        <img src={logo} alt="Kazang K Logo Fill" className="h-8" />
        <TbDotsVertical onClick={toggleKebab} className="text-white text-4xl" />
        {isKebabExpanded && (
          <div
            id="kebab-block"
            className="absolute z-20 top-3/4 bg-neutral-700 h-auto w-auto min-w-50 right-2 flex flex-col px-6 py-3 rounded-md gap-2"
          >
            {/* Temporarily hidden for user testing */}
            {/* {userData && userData.subActive && (
              <button
                onClick={() => {
                  navigate("/subscription");
                }}
                className={`${
                  !userData?.subActive ?? "hidden"
                } border-0 py-3 px-4 bg-white rounded-3xl `}
              >
                Subscribe
              </button>
            )} */}
            {userData && !userData.subActive && (
              <button
                onClick={() => {
                  navigate("/subscription");
                }}
                className={`${
                  userData?.subActive ?? "hidden"
                } border-0 py-3 px-4 bg-white rounded-3xl`}
              >
                Unsubscribe
              </button>
            )}
            {userData && (
              <div className="flex flex-col">
                <button
                  onClick={() => {
                    navigate("/admin");
                  }}
                  className={`${
                    userData?.isAdmin ?? "hidden"
                  } text-white py-3 px-4 rounded cursor-pointer select-none`}
                >
                  Admin Console
                </button>
                <button
                  className="border-0 py-3 px-4 border-none text-red-600 font-medium"
                  onClick={() => navigate("/logout")}
                >
                  Logout
                </button>
              </div>
            )}
            {!userData && (
              <button
                className="border-0 py-3 px-4 border-none text-white font-medium"
                onClick={() => navigate("/login")}
              >
                Sign in
              </button>
            )}
          </div>
        )}
      </div>
      <div className="w-full h-auto bg-neutral-800 flex justify-between px-4 py-4 border-b">
        {Object.keys(mobileNavBarDropdowns).map((continent, index) => {
          return (
            <img
              className="h-10 w-10"
              key={continent}
              src={srcMapping[continent]}
              alt={`${continent} icon`}
              onClick={() => handleContinentActivate(continent)}
            />
          );
        })}
      </div>
      {activeDropdownList.length > 0 && (
        <div
          id="activeChartList"
          className={`
            absolute w-11/12 z-30 left-2
            bg-neutral-800
            transition-all duration-300
            border-2 border-neutral-500 rounded-md
            mt-0 mx-2 overflow-hidden px-4 py-2`}
        >
          <div className="flex flex-row items-center justify-between text-white text-2xl font-semibold">
            <h3 className="capitalize">
              {activeDropdown.replace("-", " ").replace(".", "")}
            </h3>
            <TbX onClick={closeContientDropdown} />
          </div>
          {activeDropdownList.map((item, index) => {
            return (
              <Link
                to={`/`}
                key={item.id ?? index}
                state={{ id: item.id }}
                onClick={closeContientDropdown}
              >
                <div
                  className={`bg-neutral-800 py-3 cursor-pointer
                flex gap-2 w-full items-center
                text-lg text-white`}
                >
                  {item?.shortCode ? (
                    <>
                      <Flag country={item?.shortCode} size={20}></Flag>
                      {item.countryLabel}
                    </>
                  ) : (
                    <>
                      <GenreItem item={item} isMobile={true} />
                    </>
                  )}
                </div>
              </Link>
            );
          })}
          ;
        </div>
      )}
      <ul
        className={`
        ${isExpanded ? "translate-x-0" : "-translate-x-full"}
        flex flex-col w-full
        items-start text-white
        gap-5 px-4 py-4 h-lvh
        transition-all duration-300 absolute
        border-t-2 border-neutral-500
        bg-neutral-800 z-20`}
      >
        <MobileNavItem
          path={"/profile/me"}
          icon={
            userData?.profilePicture
              ? () => ProfilePicture(userData?.profilePicture)
              : TbUserCircle
          }
          text={"My Profile"}
        />
        <MobileNavItem path={"/"} icon={TbHome2} text={"Home"} />
        <MobileNavItem
          path={"/about"}
          icon={TbInfoCircle}
          text={"About KaZang"}
        />
        <MobileNavItem path={"/friends"} icon={TbUsers} text={"Friends"} />
        <MobileNavItem path={"/search"} icon={TbSearch} text={"Search"} />
        <MobileNavItem onClick={() => randomSong()} icon={Explore} text={"Explore"} />
        <MobileNavItem
          path={"/playlists"}
          icon={TbPlaylist}
          text={"Playlists"}
        />
        <MobileNavItem
          path={"/add-song"}
          icon={TbMusicPlus}
          text={"Add a song"}
        />
        <MobileNavItem path={"/library"} icon={TbLibrary} text={"Library"} />

        <div className="my-4" />
        <MobileNavItem path={"/faqs"} icon={TbQuestionMark} text={"FAQs"} />
        <MobileNavItem path={"/settings"} icon={TbLogout} text={"Logout"} />
      </ul>
    </div>
  );
};

export default MobileMenu;
