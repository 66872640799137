import { PlayIcon } from "@heroicons/react/24/outline";
import { getAnyYouTubeVideoId } from "utils/get-video-id";
const SongCard = ({ song, onClick, index }) => {

  const handleClick = e => {
    if (!onClick) {
      console.warn("click handler should be passed as prop to song card.");
      return;
    }
    onClick(e, song);
  };
  return (
    <div
      onClick={handleClick}
      className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600 w-52"
    >
      <div className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300">
        <PlayIcon className="w-8 text-white" />
      </div>
      <div className="p-2 text-lg font-medium text-light flex justify-between items-center">
        {song.chart}
        <span className="mr-1 pt-1 pb-1 text-lg rounded font-medium">
          #{index + 1}
        </span>
      </div>

      <div className="flex p-2">
        <img
          alt=""
          className="object-cover rounded"
          src={`https://img.youtube.com/vi/${getAnyYouTubeVideoId(
            song.url
          )}/mqdefault.jpg`}
        ></img>
      </div>
      <div className="p-2">
        <div className="mt-1 text-base font-semibold text-white truncate">
          {song.name}
        </div>
        <div className="text-xs font-normal truncate text-light">
          {song.artist}
        </div>
      </div>
    </div>
  );
};

export default SongCard;
