import { useContext } from "react";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

import { useNavigate, useParams } from "react-router-dom";
import { LayoutContext } from "contexts/contexts";

export default function PlaylistForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ?? props.id;
    const {updatePlaylist} = useContext(LayoutContext);

    const callBackFuc = (e) => {
        updatePlaylist({addSongResult: e?.status})
        navigate('/playlists')
    }
    return <div className="p-10">

        <div className="my-8 text-5xl font-black text-white">Create a Playlist</div>

        <FormWrapper id={'none'} defaultValue={id != "new" ? { song: id } : {}} url="user/playlist" noStyle={true} noId {...props} callback={(e) => callBackFuc(e)}>
            {(values, setValues) =>
                <div className="flex flex-col space-y-2 text-white">

                    <TextField label="Playlist Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>

                </div>
            }
        </FormWrapper>
    </div>
}